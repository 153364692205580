// App.tsx

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import TitleName from './components/titleName';
import './App.css'; // Import the CSS file

function App() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the image every 3 seconds
      setActiveIndex((prevIndex) => (prevIndex + 1) % 12);
    }, 1800);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <TitleName></TitleName>
      <div className="background-image">
        <Card className="wedding-card">
          <Card.Img
            variant="top"
            src={require(`./assets/images/${activeIndex + 1}.png`)}
            alt={`Wedding Photo ${activeIndex + 1}`}
            style={{ width: '140%', height: 'auto' }} // Set width to 100%
          />
        </Card>
      </div>
    </div>
  );
}

export default App;
