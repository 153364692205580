// YourComponent.tsx

import React, { useEffect } from 'react';

const TitleName: React.FC = () => {
  useEffect(() => {
    document.title = 'Kelly & Dave!'; // Change this line
    return () => {
      // Reset the title when the component unmounts, if needed
      document.title = 'Default Page Title'; // Change this line
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      {/* Your component content goes here */}
    </div>
  );
};

export default TitleName;
